<script setup>
import { useRoute } from 'vue-router'
import { useCookie } from 'nuxt/app'
// TODO Add the basic SEO information for each page: Title, Meta Description

// ZigZag required scripts and styles
const config = useRuntimeConfig()
const { version } = useZigZag()
const { data, refresh, error } = await version(config.public.zigzagUrl)
const time = data.value ? data.value.t : 0;
const sha = data.value ? data.value.sha : 'versionnotfound';

const route = useRoute()
// Update UTM cookies with parameters if they exist in the URL
for (let param in route.query) {
  let options = {
    maxAge: 864000 // 10 days to expire
  }
  let cookieVal = useCookie(param, options)
  cookieVal.value = route.query[param]
}

onMounted(() => {
  useHead({
    link: [
      {
        rel: 'stylesheet',
        href: `${config.public.zigzagUrl}/styles/pulseapp.css?sha=${sha}&t=${time}`
      }
    ],
    script: [
      {
        src: `${config.public.zigzagUrl}/${config.public.zigzag3rdParty}?sha=${sha}&t=${time}`,
        body: true
      }
    ],
  });
})

</script>
<template>
  <LayoutNavBar :navColor="'dark'" :hero="true" />
  <main :class="'no-hero'">
    <slot/>
  </main>
  <LayoutFooterNavigation />
  <LayoutCookieWarning v-if="route.params.cookieBanner"/>
</template>